.slide-right {
  animation: slide-right 0.5s forwards;
  -webkit-animation: slide-right 0.5s forwards;
}

.slide-left {
  animation: slide-left 0.5s forwards;
  -webkit-animation: slide-left 0.5s forwards;
}

@keyframes slide-right {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-right {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-left {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes slide-left {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0%);
  }
}
