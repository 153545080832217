.button-group > button {
  margin-left: -1px;
}

.button-group > button:first-child {
  margin-left: 0;
}

.button-group > button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button-group > button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
