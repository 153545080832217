@keyframes ROTATE-UP {
  0% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes ROTATE-DOWN {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.default-left {
  transform: rotate(-90deg);
}

.default-up {
  transform: unset;
}

.rotate-down {
  animation: ROTATE-DOWN 0.3s forwards;
}

.rotate-up {
  animation: ROTATE-UP 0.3s forwards;
}
