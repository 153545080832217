.compact-map .gmnoprint {
  display: none;
}

.compact-map .gmnoprint.gm-bundled-control {
  display: unset;
  bottom: 60px !important;
}

.compact-map .gmnoprint.gm-bundled-control  > .gmnoprint {
  display: unset;
}

.compact-map a img {
  opacity: 0;
}
