body,
html {
  background-color: #faf9f7;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.transparent::selection,
.transparent *::selection {
  background-color: transparent;
}

.axis-base path,
.axis-base line {
  fill: white; /* This will cover up graphs bleeding into margins */
  stroke-width: 1;
  stroke: #ddd;
}

.axis-base text {
  fill: #777;
  text-transform: uppercase;
}

.space-vertical-list > div:not(:first-child) {
  margin-top: 8px;
}

/*
  --- Google Maps Control Size Fix ---
  Greasy selectors are used since the map elements don't have any unique identifiers
  and the !importants are used since all of those attributes are built inline via JS
*/

.gm-bundled-control {
  bottom: 70px !important;
  right: 30px !important;
}

.gm-bundled-control > .gmnoprint > div {
  height: 60px !important;
  width: 30px !important;
}

.gm-bundled-control > .gmnoprint > div button {
  height: 30px !important;
  width: 30px !important;
}

.gm-bundled-control > .gmnoprint > div button img {
  height: 15px !important;
  width: 15px !important;
}

.gm-bundled-control > .gmnoprint > div > div {
  margin: 0 !important;
}

/* Workaround for hover on treemap leaf nodes (anomalies) */

.rv-treemap__leaf:hover {
  background: #fc7d42 !important;
}
