/*
 * Basic container. Note, the breakpoint values have been updated to reflect these values.
 */

.container {
  position: relative;
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
}

.no-padding {
  padding: 0;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
